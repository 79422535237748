$default-color: #fff;
$wcagBg:#F5F5F5;
$menuText:#777777;
$activeLink:#E04359;
$textColor:#212121;
$activeButton:#F5F5F5;
$activeButton2:$default-color;
$colorTitle:#E04359;
$colorNews:#666666;
$contactHeader:$default-color;
$contactText:$default-color;
$footerBg:#444444;
$footerText:$default-color;
$pageBg:$activeButton;
$pageBgHeader:#E04359;
$pageHeader:$default-color;
$pagination:$pageBgHeader;
$menuBorder:#CDD1D1;
$galleryText:$default-color;
$searchButton:#F0890F;
$menuBackground:$default-color;
$recomBg:$default-color;
$recomTxt:#E04359;
$menuButton:#BCBCBC;
$buttonBorder:#C8D0D2;
$nextSection:#F5F5F5;
$sectionTitle:#E04359;
$pagesBlockBg:#EBEBEB;
$pageBlockBgBtt:#F4F4F4;
$pageBlockNews:#666666;

$navbar-color: $default-color;
//primary-brand in bootstrap
$panel-color: $default-color;
//success-brand in bootstrap
$button-color: #E04359;
//body color of primary-panel
$panel-body: #eaeaea;
// light text default color
$light-text: #555;
//top section home
$topSection:$default-color;
//news widget background
$newsWidget:$activeButton;

$screen-xs:                  480px !default;
$screen-sm:                  768px !default;
$screen-md:                  1200px !default;
$screen-lg:                  1400px !default;
